
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getStorage, ref, getDownloadURL } from "firebase/storage"
import { 
  getFirestore, 
  connectFirestoreEmulator,
  doc,
  collection, 
  getDoc, 
  getDocs, 
  addDoc, 
  setDoc,
  query, 
  where,
  serverTimestamp 
} from "firebase/firestore"
import { getAnalytics, logEvent } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyASyxCqQzlmzqQgkTBudBQ75wMiVRq3AQQ",
  authDomain: "invader-app.firebaseapp.com",
  projectId: "invader-app",
  storageBucket: "invader-app.appspot.com",
  messagingSenderId: "710135970330",
  appId: "1:710135970330:web:fd071680c8444c76feafa3",
  measurementId: "G-R4SRWZ5DR6"
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)

// Initialize Analytics
export const analytics = getAnalytics(app)

// Initialize Firebase Authentication and get a reference to the service
// see: https://firebase.google.com/docs/auth/web/manage-users
export const auth = getAuth(app)

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app)
if (process.env.NODE_ENV === "development") {
  connectFirestoreEmulator(db, 'localhost', 8080);
}

export const storage = getStorage(app)

export { 
  ref, 
  getDownloadURL, 
  collection, 
  doc, 
  getDoc, 
  getDocs, 
  addDoc, 
  setDoc, 
  query, 
  where, 
  serverTimestamp,
  logEvent
}
