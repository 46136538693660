<template>
  <div v-show="loadingComplete">
    <div v-show="showFeature">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="lg:text-center">
          <h2 class="text-lg font-semibold text-indigo-600">プレミアム会員</h2>
          <p class="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">追加の機能が利用できます</p>
          <p class="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">只今、ベータ版につき通常の半額の価格で提供中です。</p>
        </div>

        <div class="mt-10">
          <dl class="space-y-10 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10 md:space-y-0">
            <div v-for="feature in features" :key="feature.name" class="relative">
              <dt>
                <div class="absolute flex h-12 w-12 items-center justify-center rounded-md bg-indigo-500 text-white">
                  <component :is="feature.icon" class="h-6 w-6" aria-hidden="true" />
                </div>
                <p class="ml-16 text-left text-lg font-medium leading-6 text-gray-900">{{ feature.name }}</p>
              </dt>
              <dd class="mt-2 text-left ml-16 text-base text-gray-500">{{ feature.description }}</dd>
            </div>
          </dl>
        </div>

        <div class="mt-10 mx-auto w-full sm:w-1/2">
          <p class="mb-2 text-sm text-red-500 text-center">
            現在ベータ版につき、330円/月が半額！<br>
            (一度申し込みすると、課金が停止するまで半額でご利用頂けます)
          </p>
          <div class="rounded-md shadow">
            <a href="#" @click.stop.prevent="stripe" class="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 md:py-4 md:px-10 md:text-lg">手続きに進む(165円/月)</a>
          </div>
          <p class="mt-4 text-sm text-gray-500 text-center">
            商品の性質上、返金はできかねます。<br>
            また、twitter社と連携を行う機能は使えなくなる場合がございます。
          </p>
        </div>
      </div>
    </div>

    <div v-show="!showFeature" class="profile-form">
      <div class="md:grid md:grid-cols-3 md:gap-6 bg-gray-100 p-4 sm:p-8">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900 text-left">会員ステータス</h3>
            <p class="mt-1 text-sm text-gray-600 text-left">お支払い状況の確認はカスタマーポータルから</p>
          </div>
        </div>
        <div class="mt-5 md:col-span-2 md:mt-0">
          <form action="#" method="POST">
            <div class="shadow sm:overflow-hidden sm:rounded-md">
              <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                <div>
                  <div>
                    <div class="rounded-md">
                      <a :href="getCustomerPotalUrl()" target="_blank" class="flex lg:w-1/2 w-full mx-auto items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 md:py-4 md:px-10 md:text-lg">カスタマーポータルへ</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="hidden sm:block" aria-hidden="true">
      <div class="py-5" />
    </div>

    <div v-show="!showFeature" class="profile-form">
      <div class="md:grid md:grid-cols-3 md:gap-6 bg-gray-100 p-4 sm:p-8">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900 text-left">フォロワー一覧</h3>
            <p class="mt-1 text-sm text-gray-600 text-left">あなたをフォローしている人たちです。定期的にフォロワー一覧の情報をバックアップしておくことができます。</p>
          </div>
        </div>
        <div class="mt-5 md:col-span-2 md:mt-0">
          <div v-if="isAccountMoves" class="shadow sm:overflow-hidden sm:rounded-md">
            <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
              <ul class="overflow-y-auto h-96 max-w divide-y divide-gray-200 dark:divide-gray-700">
                <svg v-show="loading" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 animate-spin mr-2 inline-block mb-4">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
                <li v-for="follower in followers" :key="follower.id" class="pb-3 sm:pb-4">
                    <div class="flex items-center space-x-4 pt-2">
                       <div class="flex-shrink-0">
                          <img class="w-8 h-8 rounded-full" :src="follower.icon" alt="Neil image">
                       </div>
                       <div class="flex-1 min-w-0 text-left">
                          <p class="text-sm font-medium text-black truncate">
                             {{ follower.name }}
                          </p>
                          <p class="text-sm text-gray-500 truncate dark:text-gray-400">
                             <a class="underline" :href="'https://www.twitter.com/'+follower.id" target="_blank">@{{ follower.id }}</a>
                          </p>
                       </div>
                       <div class="flex-shrink-0">
                          <button @click="deleteFollower(follower.id)" class="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">リストから削除</button>
                       </div>
                    </div>
                </li>
                <li v-show="showMore">
                  <a href="#" @click.stop.prevent="more" class="mt-4 underline block text-sm font-medium text-gray-700">もっと見る</a>
                </li>
                <li v-show="!showMore">
                  <p class="mt-4 text-sm text-red-500 text-center">データは見つかりませんでした</p>
                </li>
              </ul>
              <div>
                <div class="text-left">
                  <label for="backup" class="block text-sm font-medium text-gray-700 text-left">
                    バックアップ <span v-show="backupDateView">(最近の実行日時: {{ backupDateView }})</span>
                  </label>
                  <input v-model="followerFormData.backup" id="backup" name="backup" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" /> 有効
                </div>
                <p class="mt-2 text-sm text-left">バックアップ処理は即時に実行されません。有効後、数時間〜数日後に改めてこのページにてご確認ください。</p>
                <p class="mt-2 text-sm text-red-500 text-left">※ Twitterの仕様上、フォロワーの多い方のバックアップには数日がかかります。バックアップの途中でアカウントが凍結されると完全なバックアップができない場合がございます。</p>
                <p v-if="v$.followerFormData.backup.$error" class="mt-2 text-sm text-red-500 text-left">{{ v$.followerFormData.backup.$errors[0].$message }}</p>
              </div>
            </div>
            <div class="bg-gray-100 px-4 py-3 text-right sm:px-6">
              <button @click="sendFollower" type="submit" class="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">保存</button>
            </div>          
          </div>
          <div v-else class="shadow sm:overflow-hidden sm:rounded-md">
            <div class="space-y-6 bg-white px-4 py-5 sm:p-6 text-left">
              現在、フォロワーデータを含め、アカウントの引き継ぎ中です。<br>
              引き継ぎが完了するまでしばらくお待ちください。<br>
              フォロワーの多い方は引き継ぎに時間がかかる場合がございます。<br>
              <br>
              最近の引き継ぎ処理日時: {{ lastMovedAt }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="hidden sm:block" aria-hidden="true">
      <div class="py-5" />
    </div>

    <div v-show="!showFeature" class="profile-form">
      <div class="md:grid md:grid-cols-3 md:gap-6 bg-gray-100 p-4 sm:p-8">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900 text-left">定期的なつぶやき設定</h3>
            <p class="mt-1 text-sm text-gray-600 text-left">登録した内容を定期的に自動でつぶやくことができます</p>
          </div>
        </div>
        <div class="mt-5 md:col-span-2 md:mt-0">
          <div class="shadow sm:overflow-hidden sm:rounded-md">
            <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
              <div>
                <label for="tweet" class="block text-sm font-medium text-gray-700 text-left">つぶやきたい内容</label>
                <div class="mt-1">
                  <textarea v-model="tweetFormData.tweet" id="tweet" name="tweet" rows="6" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                </div>
                <p v-if="v$.tweetFormData.tweet.$error" class="mt-2 text-sm text-red-500 text-left">{{ v$.tweetFormData.tweet.$errors[0].$message }}</p>
              </div>
              <div>
                <div class="text-left">
                  <label for="tweetday" class="block text-sm font-medium text-gray-700 text-left">つぶやく日</label>
                  毎週
                  <select v-model="tweetFormData.tweetday" id="tweetday" name="tweetday" class="mt-1 inline-block w-1/4 rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                    <option value="-1">選択してください</option>
                    <option>日</option>
                    <option>月</option>
                    <option>火</option>
                    <option>水</option>
                    <option>木</option>
                    <option>金</option>
                    <option>土</option>
                  </select>
                  曜日
                </div>
                <p class="mt-2 text-sm text-red-500 text-left">※ 設定の反映は明日以降になる場合があります</p>              
                <p v-if="v$.tweetFormData.tweetday.$error" class="mt-2 text-sm text-red-500 text-left">{{ v$.tweetFormData.tweetday.$errors[0].$message }}</p>
              </div>
              <div>
                <div class="text-left">
                  <label for="tweethour" class="block text-sm font-medium text-gray-700 text-left">つぶやく時間</label>
                  <select v-model.number="tweetFormData.hour" id="tweethour" name="tweethour" class="mt-1 inline-block w-1/4 rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                    <option :value="-1">選択してください</option>
                    <option :value="0">0時</option>
                    <option :value="1">1時</option>
                    <option :value="2">2時</option>
                    <option :value="3">3時</option>
                    <option :value="4">4時</option>
                    <option :value="5">5時</option>
                    <option :value="6">6時</option>
                    <option :value="7">7時</option>
                    <option :value="8">8時</option>
                    <option :value="9">9時</option>
                    <option :value="10">10時</option>
                    <option :value="11">11時</option>
                    <option :value="12">12時</option>
                    <option :value="13">13時</option>
                    <option :value="14">14時</option>
                    <option :value="15">15時</option>
                    <option :value="16">16時</option>
                    <option :value="17">17時</option>
                    <option :value="18">18時</option>
                    <option :value="19">19時</option>
                    <option :value="20">20時</option>
                    <option :value="21">21時</option>
                    <option :value="22">22時</option>
                    <option :value="23">23時</option>
                  </select>
                </div>
                <p class="mt-2 text-sm text-red-500 text-left">※ 設定の反映は明日以降になる場合があります。また、実施時間は前後する場合があります。</p>              
                <p v-if="v$.tweetFormData.hour.$error" class="mt-2 text-sm text-red-500 text-left">{{ v$.tweetFormData.hour.$errors[0].$message }}</p>
              </div>          
            </div>
            <div class="bg-gray-100 px-4 py-3 text-right sm:px-6">
              <button @click="sendTweet" type="submit" class="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">保存</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="hidden sm:block" aria-hidden="true">
      <div class="py-5" />
    </div>

    <div v-show="!showFeature" class="profile-form">
      <div class="md:grid md:grid-cols-3 md:gap-6 bg-gray-100 p-4 sm:p-8">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900 text-left">アカウント引き継ぎ</h3>
            <p class="mt-1 text-sm text-gray-600 text-left">前のアカウントで設定していた内容や、バックアップしていたフォロワー情報を新しいアカウントに引き継いでくることができます。</p>
          </div>
        </div>
        <div class="mt-5 md:col-span-2 md:mt-0">
          <div v-if="isAccountMoves" class="shadow sm:overflow-hidden sm:rounded-md">
            <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
              <div>
                <div class="text-left">
                  <label class="block text-sm font-medium text-gray-700 text-left">引き継ぎキー</label>
                  {{ accesser.moveKey }}
                </div>
                <p class="mt-2 text-sm text-red-500 text-left">※ 引き継ぎキーは自分以外の人には教えないようにしてください。このアカウントを新しいアカウントに引き継ぐ時に必要になりますので、メモしておくことを推奨します。</p>              
                <p v-if="v$.tweetFormData.tweetday.$error" class="mt-2 text-sm text-red-500 text-left">{{ v$.tweetFormData.tweetday.$errors[0].$message }}</p>
              </div>
              <div>
                <label for="tweet" class="block text-sm font-medium text-gray-700 text-left">旧アカウントの引き継ぎキー</label>
                <div class="mt-1">
                  <input v-model="moveFormData.moveKey" type="text" id="moveKey" name="moveKey" rows="6" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                </div>
                <p v-show="showMoveKey" class="mt-2 text-sm text-left">
                  {{ showMoveKey }}
                </p>
                <p v-show="!doMoveKey" class="mt-2 text-sm text-left">
                  引き継ぎたいアカウントの引き継ぎキーを入力して「引き継ぎ開始」ボタンを押してください。<br>
                  引き継いでいる間は、フォロワー管理などの一部機能が利用できなくなります。<br>
                  フォロワーが多いと引き継ぎに数日かかる場合がありますのでご注意ください。<br>
                  <br>
                  なお、プレミアム会員の購入や利用期限については引き継ぎできません。<br>
                  カスタマーポータルより旧アカウントの購入を停止してから、新しいアカウントで再購入をしてください。
                </p>
                <p v-if="v$.moveFormData.moveKey.$error" class="mt-2 text-sm text-red-500 text-left">{{ v$.moveFormData.moveKey.$errors[0].$message }}</p>

                <div v-show="doMoveKey" class="mt-1">
                  <input v-model="moveFormData.confirmKey" type="text" id="confirmKey" name="confirmKey" rows="6" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                </div>
                <p v-if="v$.moveFormData.confirmKey.$error" class="mt-2 text-sm text-red-500 text-left">{{ v$.moveFormData.confirmKey.$errors[0].$message }}</p>

              </div>      
            </div>
            <div class="bg-gray-100 px-4 py-3 text-right sm:px-6">
              <button :disabled='loadingMoveAcount' @click="startAccountMove" type="submit" class="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                引き継ぎ開始
                <svg v-show="loadingMoveAcount" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 animate-spin mr-2 inline-block">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
              </button>
            </div>
          </div>
          <div v-else class="shadow sm:overflow-hidden sm:rounded-md">
            <div class="space-y-6 bg-white px-4 py-5 sm:p-6 text-left">
              現在、フォロワーデータを含め、アカウントの引き継ぎ中です。<br>
              引き継ぎが完了するまでしばらくお待ちください。<br>
              フォロワーの多い方は引き継ぎに時間がかかる場合がございます。<br>
              <br>
              最近の引き継ぎ処理日時: {{ lastMovedAt }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="hidden sm:block" aria-hidden="true">
      <div class="py-5" />
    </div>

  </div>
</template>

<script>
import { auth, db, collection, doc, getDocs, addDoc, setDoc, query, where, serverTimestamp } from '@/firebaseInit.js'
import { useVuelidate } from '@vuelidate/core'
import { helpers, required, maxLength, integer, between, requiredIf } from '@vuelidate/validators'
import { CheckIcon } from '@heroicons/vue/24/outline'
// see: https://docs.vuestripe.com/vue-stripe/stripe-checkout/subscriptions
// see: https://stripe.com/docs/payments/checkout/client-subscription#enable-checkout
import { format } from 'date-fns'

const contains = (params) => (value) => params.some(param => value.indexOf(param) !== -1)
const checked  = (value) => value === true || value === false

export default {
  name: 'PremiumForm',
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      loadingComplete: false,
      showFeature: true,
      showMore: true,
      loading: false,
      loadingMoveAcount: false,
      sessionId: '',
      sessionUrl: '',
      accesser: {},
      followers: [],
      followersLastVisible: '',
      followerbackupsId: '',
      autotweetsId: '',
      backupDateView: '',
      isAccountMoves: true,
      lastMovedAt: '',
      doMoveKey: false,
      showMoveKey: '',
      followerFormData: {
        uid: '',
        backup: false,
        nextCursor: '',
        backupDate: serverTimestamp(),
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      },
      tweetFormData: {
        uid: '',
        tweet: '',
        tweetday: '-1',
        hour: -1,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      },
      moveFormData: {
        moveKey: '',
        confirmKey: '',
      },
      features: [
        {
          name: 'アカウント引き継ぎ',
          description:
            'プロフィールを登録しているTwitterアカウントが凍結された場合でも、新しいアカウントに情報を引き継ぐことができます。※ フォロワー情報などの一部データは引き継ぎに時間がかかる場合があります。',
          icon: CheckIcon,
        },
        {
          name: 'フォロワー管理',
          description:
            'あなたの大切なフォロワーさんの情報を保存しておくことができます。万が一、Twitterアカウントが凍結されても新しいアカウントからフォロワーさんを探すことができます。※ フォロワーさんの保存にはTwitter社の仕様上、数日〜かかる場合があります。',
          icon: CheckIcon,
        },
        {
          name: '自動つぶやき機能',
          description:
            '毎週決まった曜日の決まった時間帯に設定した内容のつぶやきを自動で行うことができます。※ つぶやく時間は多少前後する場合がございます。',
          icon: CheckIcon,
        },
        {
          name: '広告なし',
          description:
            '広告配信を行う場合でも、プロフィール画面を含む全ページにおいて広告が表示されません。',
          icon: CheckIcon,
        },
        {
          name: 'プレミアム会員専用テンプレート',
          description:
            '今後提供予定のテンプレートにおいて、プレミアム会員専用のテンプレートを用意する予定です。※ ベータ版の現在、まだ提供はありません。',
          icon: CheckIcon,
        },
        {
          name: '今後も追加予定！',
          description:
            'プレミアム会員専用の機能はまだまだ追加予定です。',
          icon: CheckIcon,
        },
      ]
    }
  },
  async created() {
    if(auth.currentUser && auth.currentUser.uid) {
      this.accesser = await this.getAccesser(auth.currentUser.uid);

      const stripe = await this.getStripes(auth.currentUser.uid)

      if (Object.keys(stripe).length > 0 && !this.isExpired(stripe.expire.toDate())) {
        // 有効期限内のユーザ

        // 特徴を表示しない
        this.showFeature = false

        // 各設定を取得
        await this.getFollowerBackup(auth.currentUser.uid)
        await this.getAutoTweets(auth.currentUser.uid)

        // get follower
        this.getFollowerList(auth.currentUser.uid, this.lastVisible)

        await this.checkAccountMoves(auth.currentUser.uid)
      } else {
        // 未課金または期限切れのユーザは課金準備
        this.axios.post(process.env.VUE_APP_FUNCTIONS_BACKEND + 'session', {
          success_url: process.env.VUE_APP_STRIPE_SUCCESS,
          cancel_url: process.env.VUE_APP_STRIPE_CANCEL,
          payment_method_types: [
            'card'
          ],
          line_items: [
            {
              price: process.env.VUE_APP_STRIPE_PRICE_ID,
              quantity: 1,
            }
          ],
          mode: 'subscription',
          metadata: {
            uid: auth.currentUser.uid
          }
        })
        .then((response) => {
          this.sessionId = response.data.id
          this.sessionUrl = response.data.url
        })
      }
    }
    this.loadingComplete = true
  },
  validations () {
    return {
      followerFormData: {
        uid: {
          required,
        },
        backup: {
          checked: helpers.withMessage('無効な値です', checked),
        },
      },
      tweetFormData: {
        uid: {
          required,
        },
        tweet: {
          maxLength: helpers.withMessage('140文字以内で入力してください', maxLength(140)),
        },
        tweetday: {
          contains: helpers.withMessage('無効な値です', contains(['', '日', '月', '火', '水', '木', '金', '土'])),
        },
        hour: {
          integer,
          betweenValue: between(-1, 23),
        },
      },
      moveFormData: {
        moveKey: {
          required: helpers.withMessage('引き継ぎキーを入力してください', required),
          maxLength: helpers.withMessage('無効な値です', maxLength(50)),
        },
        confirmKey: {
          requiredIf: helpers.withMessage('引き継ぎキーを入力してください', requiredIf(this.doMoveKey)),
          maxLength: helpers.withMessage('無効な値です', maxLength(50)),
        }        
      }
    }
  },
  methods: {
    stripe() {
      // You will be redirected to Stripe's secure checkout page
      if (this.sessionUrl) {
        location.href = this.sessionUrl
      }
    },
    async getStripes(uid) {
      const q = query(collection(db, "stripes"), where("uid", "==", uid))

      let stripe = {}
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        stripe = doc.data()
        stripe.docId = doc.id
      })

      return stripe
    },
    isExpired(expireDate) {
      const now = new Date()
      const expire = expireDate

      if (now.getTime() <= expire.getTime()) {
        return false
      }
      return true
    },
    async getAccesser(uid) {
      const q = query(collection(db, "accessers"), where("uid", "==", uid))

      let accesser = {}
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        accesser = doc.data()
        accesser.docId = doc.id
      })

      return accesser
    },
    async getFollowerBackup(uid) {
      const q = query(collection(db, "followerbackups"), where("uid", "==", uid))

      let backup = {}
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        this.followerbackupsId = doc.id
        backup = doc.data()
      })

      if (Object.keys(backup).length > 0) {
        this.backupDateView = format(backup.backupDate.toDate(), 'yyyy-MM-dd HH:mm:ss')
        this.followerFormData = backup
        this.followerFormData.createdAt = backup.createdAt.toDate()
      }

      return backup
    },
    async getAutoTweets(uid) {
      const q = query(collection(db, "autotweets"), where("uid", "==", uid))

      let tweet = {}
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        this.autotweetsId = doc.id
        tweet = doc.data()
      })

      if (Object.keys(tweet).length > 0) {
        this.tweetFormData = tweet
        this.tweetFormData.createdAt = tweet.createdAt.toDate()
      }

      return tweet
    },
    async sendFollower () {
      this.followerFormData.uid = auth.currentUser.uid
      this.followerFormData.updatedAt = serverTimestamp()

      const result = await this.v$.followerFormData.$validate()
      if (!result) {
        return
      }

      if (this.followerbackupsId == '') {
        await addDoc(collection(db, "followerbackups"), this.followerFormData)
      } else {
        await setDoc(doc(db, "followerbackups", this.followerbackupsId), this.followerFormData)
      }
      this.$router.go({path: this.$router.currentRoute.path, force: true});
    },
    async sendTweet () {
      this.tweetFormData.uid = auth.currentUser.uid
      this.tweetFormData.updatedAt = serverTimestamp()

      const result = await this.v$.tweetFormData.$validate()
      if (!result) {
        return
      }

      if (this.autotweetsId == '') {
        await addDoc(collection(db, "autotweets"), this.tweetFormData)
      } else {
        await setDoc(doc(db, "autotweets", this.autotweetsId), this.tweetFormData)
      }
      this.$router.go({path: this.$router.currentRoute.path, force: true});
    },
    async getFollowerList(uid, lastVisible) {
      this.loading = true;

      this.axios.post(process.env.VUE_APP_FUNCTIONS_BACKEND + 'followerList', {
        uid: uid,
        lastVisible: lastVisible,
      })
      .then((response) => {
        if (this.followers.length === 0) {
          this.followers = response.data.followers
        } else {
          if (response.data.followers.length > 0) {
            this.followers = this.followers.concat(response.data.followers)
          }
        }
        this.followersLastVisible = response.data.lastVisible
        if (response.data.lastVisible === 'nodata') {
          this.showMore = false
        }

        this.loading = false;
      })
    },
    more() {
      if (this.followersLastVisible === 'nodata') {
        this.showMore = false
      } else {
        this.getFollowerList(auth.currentUser.uid, this.followersLastVisible)
      }
    },
    async deleteFollower(followerId) {
      await this.axios.post(process.env.VUE_APP_FUNCTIONS_BACKEND + 'followerDelete', {
        uid: auth.currentUser.uid,
        id: followerId,
      })
      // 再読み込み
      this.showMore = true
      this.followersLastVisible = ''
      this.followers = []
      this.getFollowerList(auth.currentUser.uid, this.lastVisible)
    },
    async checkAccountMoves(uid) {
      // 新しいアカウントで引き継ぎ中か
      const queryConstraints = []
      queryConstraints.push(where("uid", "==", uid))
      queryConstraints.push(where("completed", "==", false))

      const q = query(collection(db, "followermoves"), ...queryConstraints)
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        this.isAccountMoves = false
        if (doc.data().lastMovedAt) {
          this.lastMovedAt = format(doc.data().lastMovedAt.toDate(), 'yyyy-MM-dd HH:mm:ss')
        }
      })

      // 古いアカウントで引き継ぎ中か
      const queryConstraintsOr = []
      queryConstraintsOr.push(where("oldUid", "==", uid))
      queryConstraintsOr.push(where("completed", "==", false))

      const qor = query(collection(db, "followermoves"), ...queryConstraintsOr)
      const querySnapshotOr = await getDocs(qor)
      querySnapshotOr.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        this.isAccountMoves = false
        if (doc.data().lastMovedAt) {
          this.lastMovedAt = format(doc.data().lastMovedAt.toDate(), 'yyyy-MM-dd HH:mm:ss')
        }
      })
    },
    getCustomerPotalUrl() {
      return process.env.VUE_APP_STRIPE_CUSTOMER_POTAL;
    },
    async startAccountMove() {
      const result = await this.v$.moveFormData.$validate()
      if (!result) {
        this.moveFormData.moveKey = ''
        this.doMoveKey = false
        this.showMoveKey = ''
        return
      }

      this.loadingMoveAcount = true

      if (this.doMoveKey) {
        this.axios.post(process.env.VUE_APP_FUNCTIONS_BACKEND + 'accountMove', {
          uid: auth.currentUser.uid,
          confirmKey: this.moveFormData.confirmKey,
          moveKey: this.moveFormData.moveKey,
        })
        .then(() => {
          this.loadingMoveAcount = false
          this.signout()
        })
        .catch(error => {
          this.loadingMoveAcount = false
          alert(error.response.data.error)
        })
      } else {
        this.axios.post(process.env.VUE_APP_FUNCTIONS_BACKEND + 'checkKey', {
          moveKey: this.moveFormData.moveKey,
        })
        .then((response) => {
          if (Object.keys(response.data).length > 0) {
            this.doMoveKey = true
            this.showMoveKey = '旧アカウントが見つかりました。アカウントの引き継ぎが可能です。以下のフォームにこのアカウントの引き継ぎキーを入力して、もう一度「引き継ぎ開始」のボタンを押して引き継ぎを開始してください。'
          } else {
            this.doMoveKey = false
            this.showMoveKey = '旧アカウントは見つかりませんでした。'
          }
          this.loadingMoveAcount = false
        })
      }
    },
    signout () {
      auth.signOut().then(() => {
        this.$router.push('/')

      }).catch((error) => {
        console.log(error)
      })
    }    
  }  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
