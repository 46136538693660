<template>
  <HeaderComponent/>
  <div v-if="showNoProfile" class="home container mx-auto bg-white py-8">
    <NoProfile :nodata="nodata" />
  </div>
  <div v-if="showDefaultProfile" class="home container mx-auto bg-white py-8">
    <DefaultProfile :profile="profile" />
  </div>
  <div class="sm:block" aria-hidden="true">
    <div class="border-b-2 border-gray-100" />
  </div>
  <div class="text-center my-2">
    copyright (c) S-Profile all rights reserved.
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderComponent from '@/components/HeaderComponent.vue'
import { auth, storage, ref, getDownloadURL, db, doc, getDoc } from '@/firebaseInit.js'
import NoProfile from '@/components/NoProfile.vue'
import DefaultProfile from '@/components/DefaultProfile.vue'

export default {
  name: 'ProfileView',
  components: {
    HeaderComponent,
    NoProfile,
    DefaultProfile
  },
  data() {
    return {
      showNoProfile: false,
      showDefaultProfile: false,
      profile: {},
      nodata: false,
    }
  },
  async created() {
    // 本人のプロフィール
    this.profile = await this.getProfiles(this.$route.params.id)

    if(auth.currentUser && auth.currentUser.uid === this.profile.uid) {
      if (this.profile.template === 0) {
        this.showDefaultProfile = true
      } else {
        // Todo: show templates
        // 専用ページ作った方が良さそう
      }
    // 第三者のプロフィール
    } else {
      if (this.profile.template > 0) {
        // Todo: show templates
      } else {
        this.showNoProfile = true        
      }
    }
  },
  methods: {
    async getProfiles (docId) {
      const docRef = doc(db, "profiles", docId);
      const docSnap = await getDoc(docRef);

      let profile = {}

      if (docSnap.exists()) {
        profile = docSnap.data()
        await this.setStoragePath(profile, profile.icon, 'viewIcon')
        await this.setStoragePath(profile, profile.qr, 'viewQr')
        await this.setStoragePath(profile, profile.screenshot, 'viewScreenshot')
      } else {
        this.nodata = true;
      }

      return profile
    },
    async setStoragePath(profile, image, key) {
      const downloadUrl = await this.getStoragePath(image)
      if (downloadUrl) {
        profile[key] = downloadUrl
      } else {
        profile[key] = ''
      }
    },
    async getStoragePath(fileName) {
      if (!fileName) {
        return ''
      }
      const pathReference = ref(storage, `save/${fileName}`)

      return await getDownloadURL(pathReference)
        .then((url) => {
          return url
        })
        .catch((error) => {
          console.log(error)
          return ''
        })
    }
  }
}
</script>
