<template>
  <div>
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="lg:text-center">
        <p class="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">準備中です</p>
        <p v-if="nodata" class="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
          プロフィールがまだ登録されていません。プロフィールを登録した後、このページからデザインテンプレートを申請してください。
        </p>
        <p v-else class="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">このページは只今準備中です。しばらくしてから再度アクセスしてください。</p>
      </div>
    </div>
  </div>

  <div class="hidden sm:block" aria-hidden="true">
    <div class="py-5" />
  </div>
</template>

<script>
export default {
  name: 'NoProfile',
  props: {
    nodata: Boolean
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
