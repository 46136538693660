<template>
  <HeaderComponent/>
  <div class="home">
    <TemplateList/>
  </div>
  <div class="sm:block" aria-hidden="true">
    <div class="border-b-2 border-gray-100" />
  </div>
  <div class="text-center my-2">
    copyright (c) S-Profile all rights reserved.
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderComponent from '@/components/HeaderComponent.vue'
import TemplateList from '@/components/TemplateList.vue'

export default {
  name: 'TemplateView',
  components: {
    HeaderComponent,
    TemplateList
  }
}
</script>
