<template>
  <div class="profile-form">
    <div class="text-lg font-medium leading-6 text-gray-900 mb-4 px-4 text-left sm:text-center">
      M男さんに伝えたい内容を入力してください。<br>
      <span class="text-red-500">設定後、プロフィールページよりデザインテンプレートの適用を申請してください。</span>
    </div>
    <div class="md:grid md:grid-cols-3 md:gap-6 bg-gray-100 p-4 sm:p-8">
      <div class="md:col-span-1">
        <div class="px-4 sm:px-0">
          <h3 class="text-lg font-medium leading-6 text-gray-900 text-left">プロフィール</h3>
          <p class="mt-1 text-sm text-gray-600 text-left">あなたのことを教えてください</p>
        </div>
      </div>
      <div class="mt-5 md:col-span-2 md:mt-0">
        <form action="#" method="POST">
          <div class="shadow sm:overflow-hidden sm:rounded-md">
            <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
              <div>
                <div>
                  <label for="name" class="block text-sm font-medium text-gray-700 text-left">名前 <span class="mt-4 text-sm text-red-500 text-center">※</span></label>
                  <input v-model="formData.name" type="text" name="name" id="name" autocomplete="nickname" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                  <p v-if="v$.formData.name.$error" class="mt-2 text-sm text-red-500 text-left">{{ v$.formData.name.$errors[0].$message }}</p>
                </div>
              </div>

              <div>
                <label class="block text-sm font-medium text-gray-700 text-left">アイコン画像</label>
                <div class="mt-1 flex items-center">
                  <span v-show="!viewImage.icon" class="inline-block h-12 w-12 overflow-hidden rounded-full bg-gray-100">
                    <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  </span>
                  <span v-show="viewImage.icon" class="inline-block h-12 w-12 overflow-hidden rounded-full bg-gray-100">
                    <img :src="viewImage.icon">
                  </span>
                  <a v-show="viewImage.icon" @click.stop.prevent="deleteAction('icon')" class="block ml-2 text-sm font-medium text-gray-700 underline" href="#">削除</a>
                </div>
              </div>

              <div>
                <file-pond
                  name="icon"
                  ref="pond"
                  label-idle="ここにファイルをドロップしてください"
                  v-bind:allow-multiple="false"
                  accepted-file-types="image/*"
                  :server="getUploadServerPath('icon')"
                  v-bind:files="files.icons"
                  v-on:init="handleFilePondInit"
                  v-bind:allow-image-crop="true"
                  image-crop-aspect-ratio="1:1"
                />
              </div>

              <div>
                <div>
                  <label for="comment" class="block text-sm font-medium text-gray-700 text-left">一言</label>
                  <input v-model="formData.comment" type="text" name="comment" id="comment" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                  <p v-if="v$.formData.comment.$error" class="mt-2 text-sm text-red-500 text-left">{{ v$.formData.comment.$errors[0].$message }}</p>
                </div>
              </div>

              <div>
                <label for="works" class="block text-sm font-medium text-gray-700 text-left">実績</label>
                <div class="mt-1">
                  <textarea v-model="formData.works" id="works" name="works" rows="6" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                </div>
                <p class="mt-2 text-sm text-gray-500 text-left">毎日10人調教しています/家畜プレイ得意です...など</p>
                <p v-if="v$.formData.works.$error" class="mt-2 text-sm text-red-500 text-left">{{ v$.formData.works.$errors[0].$message }}</p>
              </div>

              <div>
                <div>
                  <label for="tags" class="block text-sm font-medium text-gray-700 text-left">あなたを表すハッシュタグ</label>
                  <input v-model="formData.tags" type="text" name="tags" id="tags" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                </div>
                <p class="mt-2 text-sm text-gray-500 text-left">#S女 #M男 #調教 #貢ぎ奴隷 #貢ぎマゾ #DM調教...など</p>
                <p v-if="v$.formData.tags.$error" class="mt-2 text-sm text-red-500 text-left">{{ v$.formData.tags.$errors[0].$message }}</p>
              </div>

              <div>
                <div>
                  <label for="website" class="block text-sm font-medium text-gray-700 text-left">ウェブサイト</label>
                  <input v-model="formData.website" type="text" name="website" id="website" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="www.example.com" />
                </div>
                <p v-if="v$.formData.website.$error" class="mt-2 text-sm text-red-500 text-left">{{ v$.formData.website.$errors[0].$message }}</p>
              </div>

            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="hidden sm:block" aria-hidden="true">
    <div class="py-5" />
  </div>

  <div class="profile-form">
    <div class="md:grid md:grid-cols-3 md:gap-6 bg-gray-100 p-4 sm:p-8">
      <div class="md:col-span-1">
        <div class="px-4 sm:px-0">
          <h3 class="text-lg font-medium leading-6 text-gray-900 text-left">お貢ぎ</h3>
          <p class="mt-1 text-sm text-gray-600 text-left">お貢ぎを希望する場合の条件を教えてください</p>
        </div>
      </div>
      <div class="mt-5 md:col-span-2 md:mt-0">
        <form action="#" method="POST">
          <div class="shadow sm:overflow-hidden sm:rounded-md">
            <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
              <div>
                <div class="col-span-6 sm:col-span-3">
                  <label for="ispay" class="block text-sm font-medium text-gray-700 text-left">お貢ぎの有無 <span class="mt-4 text-sm text-red-500 text-center">※</span></label>
                  <select v-model="formData.ispay" id="ispay" name="ispay" class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                    <option>有り</option>
                    <option>無し</option>
                    <option>どちらでも</option>
                  </select>
                </div>
                <p v-if="v$.formData.ispay.$error" class="mt-2 text-sm text-red-500 text-left">{{ v$.formData.ispay.$errors[0].$message }}</p>
              </div>

              <div>
                <div class="col-span-6 sm:col-span-3">
                  <label for="timing" class="block text-sm font-medium text-gray-700 text-left">お貢ぎのタイミング <span class="mt-4 text-sm text-red-500 text-center">※</span></label>
                  <select v-model="formData.timing" id="timing" name="timing" class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                    <option>先払い</option>
                    <option>後払い</option>
                    <option>応相談</option>
                  </select>
                </div>
                <p v-if="v$.formData.timing.$error" class="mt-2 text-sm text-red-500 text-left">{{ v$.formData.timing.$errors[0].$message }}</p>
              </div>

              <div>
                <div>
                  <label for="payment" class="block text-sm font-medium text-gray-700 text-left">対応決済</label>
                  <input v-model="formData.payment" type="text" name="payment" id="payment" placeholder="例: PayPay / アマギフ" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                  <p v-if="v$.formData.payment.$error" class="mt-2 text-sm text-red-500 text-left">{{ v$.formData.payment.$errors[0].$message }}</p>
                </div>
              </div>

              <div>
                <label class="block text-sm font-medium text-gray-700 text-left">お貢ぎ先 (QR画像)</label>
                <div class="mt-1 flex items-center">
                  <span v-show="viewImage.qr" class="inline-block w-1/2 overflow-hidden bg-gray-100">
                    <img :src="viewImage.qr">
                  </span>
                  <a v-show="viewImage.qr" @click.stop.prevent="deleteAction('qr')" class="block ml-2 text-sm font-medium text-gray-700 underline" href="#">削除</a>
                </div>
              </div>

              <div>
                <file-pond
                  name="qr"
                  ref="pond"
                  label-idle="ここにファイルをドロップしてください"
                  v-bind:allow-multiple="false"
                  accepted-file-types="image/*"
                  :server="getUploadServerPath('qr')"
                  v-bind:files="files.qrs"
                  v-on:init="handleFilePondInit"
                />
              </div>

            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="hidden sm:block" aria-hidden="true">
    <div class="py-5" />
  </div>

  <div class="profile-form">
    <div class="md:grid md:grid-cols-3 md:gap-6 bg-gray-100 p-4 sm:p-8">
      <div class="md:col-span-1">
        <div class="px-4 sm:px-0">
          <h3 class="text-lg font-medium leading-6 text-gray-900 text-left">M男くんへ</h3>
          <p class="mt-1 text-sm text-gray-600 text-left">M男くんに伝えたいことを教えてください</p>
        </div>
      </div>
      <div class="mt-5 md:col-span-2 md:mt-0">
        <form action="#" method="POST">
          <div class="shadow sm:overflow-hidden sm:rounded-md">
            <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
              <div>
                <label for="condition" class="block text-sm font-medium text-gray-700 text-left">M男くんに求める条件</label>
                <div class="mt-1">
                  <textarea v-model="formData.condition" id="condition" name="condition" rows="3" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                </div>
                <p class="mt-2 text-sm text-gray-500 text-left">18歳未満はNG/お貢ぎ必須/顔出しできる人...など</p>
                <p v-if="v$.formData.condition.$error" class="mt-2 text-sm text-red-500 text-left">{{ v$.formData.condition.$errors[0].$message }}</p>
              </div>

              <div>
                <label for="feature" class="block text-sm font-medium text-gray-700 text-left">私の調教はここが違うよ！</label>
                <div class="mt-1">
                  <textarea v-model="formData.feature" id="feature" name="feature" rows="3" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                </div>
                <p class="mt-2 text-sm text-gray-500 text-left">とにかく焦らすことが得意だよ！...など</p>
                <p v-if="v$.formData.feature.$error" class="mt-2 text-sm text-red-500 text-left">{{ v$.formData.feature.$errors[0].$message }}</p>
              </div>

              <div>
                <label for="howto" class="block text-sm font-medium text-gray-700 text-left">調教方法</label>
                <div class="mt-1">
                  <div class="flex items-start">
                    <div class="flex h-5 items-center">
                      <input v-model="formData.dm" id="dm" name="dm" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                      <p v-if="v$.formData.dm.$error" class="mt-2 text-sm text-red-500 text-left">{{ v$.formData.dm.$errors[0].$message }}</p>
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="dm" class="font-medium text-gray-700">DM</label>
                    </div>
                  </div>
                  <div class="flex items-start">
                    <div class="flex h-5 items-center">
                      <input v-model="formData.tel" id="tel" name="tel" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                      <p v-if="v$.formData.tel.$error" class="mt-2 text-sm text-red-500 text-left">{{ v$.formData.tel.$errors[0].$message }}</p>
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="tel" class="font-medium text-gray-700">通話</label>
                    </div>
                  </div>
                  <div class="flex items-start">
                    <div class="flex h-5 items-center">
                      <input v-model="formData.voice" id="voice" name="voice" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                      <p v-if="v$.formData.voice.$error" class="mt-2 text-sm text-red-500 text-left">{{ v$.formData.voice.$errors[0].$message }}</p>
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="voice" class="font-medium text-gray-700">ボイメ</label>
                    </div>
                  </div>
                  <div class="flex items-start">
                    <div class="flex h-5 items-center">
                      <input v-model="formData.kakao" id="kakao" name="kakao" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                      <p v-if="v$.formData.kakao.$error" class="mt-2 text-sm text-red-500 text-left">{{ v$.formData.kakao.$errors[0].$message }}</p>
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="kakao" class="font-medium text-gray-700">カカオ</label>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <label for="menu" class="block text-sm font-medium text-gray-700 text-left">調教メニュー</label>
                <div class="mt-1">
                  <textarea v-model="formData.menu" id="menu" name="menu" rows="6" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                </div>
                <p class="mt-2 text-sm text-gray-500 text-left">通話調教 5分 1000円 ...など</p>
                <p v-if="v$.formData.menu.$error" class="mt-2 text-sm text-red-500 text-left">{{ v$.formData.menu.$errors[0].$message }}</p>
              </div>

              <div>
                <label class="block text-sm font-medium text-gray-700 text-left">調教イメージ (DMスクショなど)</label>
                <div class="mt-1 flex items-center">
                  <span v-show="viewImage.screenshot" class="inline-block w-1/2 overflow-hidden bg-gray-100">
                    <img :src="viewImage.screenshot">
                  </span>
                  <a v-show="viewImage.screenshot" @click.stop.prevent="deleteAction('screenshot')" class="block ml-2 text-sm font-medium text-gray-700 underline" href="#">削除</a>
                </div>
              </div>

              <div>
                <file-pond
                  name="screenshot"
                  ref="pond"
                  label-idle="ここにファイルをドロップしてください"
                  v-bind:allow-multiple="false"
                  accepted-file-types="image/*"
                  :server="getUploadServerPath('screenshot')"
                  v-bind:files="files.screenshots"
                  v-on:init="handleFilePondInit"
                />
              </div>

            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="hidden sm:block" aria-hidden="true">
    <div class="py-5" />
  </div>

  <div class="container mx-auto sticky bottom-0">
    <div class="bg-gray-100 px-4 py-3 text-right sm:px-6">
      <button @click="send" :disabled='loading' type="submit" class="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
        <svg v-show="loading" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 animate-spin mr-2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
        </svg>
        保存
      </button>
    </div>
  </div>

</template>

<script>
import { 
  auth, 
  storage, 
  ref, 
  getDownloadURL, 
  db, 
  collection, 
  doc, 
  getDocs, 
  addDoc, 
  setDoc, 
  query, 
  where, 
  serverTimestamp 
} from '@/firebaseInit.js'
import { useVuelidate } from '@vuelidate/core'
import { helpers, required, maxLength, url, integer } from '@vuelidate/validators'
import vueFilePond from "vue-filepond"
import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css"

import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import FilePondPluginImageCrop from 'filepond-plugin-image-crop'
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'

const FilePond = vueFilePond(
  FilePondPluginImagePreview,
  FilePondPluginImageCrop,
  FilePondPluginImageTransform,
  FilePondPluginFileValidateType
);

const contains = (params) => (value) => params.some(param => value.indexOf(param) !== -1)
const checked  = (value) => value === true || value === false

// see: https://vuelidate-next.netlify.app/
export default {
  name: 'ProfileForm',
  components: {
    FilePond,
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      id: '',
      loading: false,
      formData: {
        uid: '',
        name: '',
        icon: '',
        comment: '',
        works: '',
        tags: '',
        website: '',
        ispay: 'どちらでも',
        timing: '応相談',
        payment: '',
        qr: '',
        condition: '',
        feature: '',
        dm: false,
        tel: false,
        voice: false,
        kakao: false,
        menu: '',
        screenshot: '',
        template: 0,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      },
      files: {
        icons: [],
        qrs: [],
        screenshots: [],
      },
      viewImage: {
        icon: '',
        qr: '',
        screenshot: '',
      },
      oldImage: {
        icon: '',
        qr: '',
        screenshot: '',
      }
    }
  },
  created() {
    if(auth.currentUser.uid) {
      this.getProfiles(auth.currentUser.uid)
    }
  },
  validations () {
    return {
      formData: {
        uid: {
          required,
        },
        name: {
          required: helpers.withMessage('名前を入力してください', required),
          maxLength: helpers.withMessage('10文字以内で入力してください', maxLength(50)),
        },
        icon: {
          maxLength: helpers.withMessage('255文字以内で入力してください', maxLength(255)),
        },
        comment: {
          maxLength: helpers.withMessage('255文字以内で入力してください', maxLength(255)),
        },
        works: {
          maxLength: helpers.withMessage('1000文字以内で入力してください', maxLength(1000)),
        },
        tags: {
          maxLength: helpers.withMessage('255文字以内で入力してください', maxLength(255)),
        },
        website: {
          url: helpers.withMessage('無効なURL形式です', url),
          maxLength: helpers.withMessage('255文字以内で入力してください', maxLength(255)),
        },
        ispay: {
          contains: helpers.withMessage('無効な値です', contains(['有り', '無し', 'どちらでも'])),
        },
        timing: {
          contains: helpers.withMessage('無効な値です', contains(['先払い', '後払い', '応相談'])),
        },
        payment: {
          maxLength: helpers.withMessage('1000文字以内で入力してください', maxLength(1000)),
        },
        qr: {
          maxLength: helpers.withMessage('255文字以内で入力してください', maxLength(255)),
        },
        condition: {
          maxLength: helpers.withMessage('9999文字以内で入力してください', maxLength(9999)),
        },
        feature: {
          maxLength: helpers.withMessage('9999文字以内で入力してください', maxLength(9999)),
        },
        dm: {
          checked: helpers.withMessage('無効な値です', checked),
        },
        tel: {
          checked: helpers.withMessage('無効な値です', checked),
        },
        voice: {
          checked: helpers.withMessage('無効な値です', checked),
        },
        kakao: {
          checked: helpers.withMessage('無効な値です', checked),
        },
        menu: {
          maxLength: helpers.withMessage('9999文字以内で入力してください', maxLength(99)),
        },
        screenshot: {
          maxLength: helpers.withMessage('255文字以内で入力してください', maxLength(255)),
        },
        template: {
          required,
          integer,
        },
      }
    }
  },
  methods: {
    async send () {
      this.loading = true;

      this.formData.uid = auth.currentUser.uid
      this.formData.updatedAt = serverTimestamp()

      const result = await this.v$.$validate()
      if (!result) {
        return
      }

      if (this.id == '') {
        await addDoc(collection(db, "profiles"), this.formData)
        await this.imageMove('icon')
        await this.imageMove('qr')
        await this.imageMove('screenshot')
      } else {
        await this.imageDelete('icon')
        await this.imageDelete('qr')
        await this.imageDelete('screenshot')

        await setDoc(doc(db, "profiles", this.id), this.formData)
        await this.imageMove('icon')
        await this.imageMove('qr')
        await this.imageMove('screenshot')
      }
      this.$router.go({path: this.$router.currentRoute.path, force: true});
    },
    async getProfiles (uid) {
      const q = query(collection(db, "profiles"), where("uid", "==", uid))

      let editData = {}
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        this.id = doc.id

        editData = {
          ...this.formData,
          ...doc.data()
        }
        editData.createdAt = doc.data().createdAt.toDate()
      })

      if (Object.keys(editData).length > 0) {
        this.formData = editData

        this.setStoragePath(editData.icon, 'icon')
        this.setStoragePath(editData.qr, 'qr')
        this.setStoragePath(editData.screenshot, 'screenshot')
      }
    },
    handleFilePondInit: function () {
      console.log("FilePond has initialized")

      // FilePond instance methods are available on `this.$refs.pond`
    },
    getUploadServerPath(key) {
      return {
        url: process.env.VUE_APP_FUNCTIONS_BACKEND,
        process: {
          url: './imageUpload',
          onload: (response) => this.formData[key] = JSON.parse(response)[0],
        }
      }
    },
    async getStoragePath(fileName) {
      if (!fileName) {
        return ''
      }
      const pathReference = ref(storage, `save/${fileName}`)

      return await getDownloadURL(pathReference)
        .then((url) => {
          return url
        })
        .catch((error) => {
          console.log(error)
          return ''
        })
    },
    async deleteAction(key) {
      if (this.id && this.formData[key]) {
        await this.axios.post(process.env.VUE_APP_FUNCTIONS_BACKEND + 'imageDelete', {
          key: key,
          docId: this.id,
          image: this.formData[key],
        })
        this.formData[key] = ''
        this.oldImage[key] = ''
        this.viewImage[key] = ''
      }
    },
    async imageMove(key) {
      // move image to save dir
      if (this.formData[key] && this.formData[key] != this.oldImage[key]) {
        await this.axios.post(process.env.VUE_APP_FUNCTIONS_BACKEND + 'imageMove', {
          image: this.formData[key],
        })
      }
    },
    async imageDelete(key) {
      // delete old image
      if (this.id && this.oldImage[key] && this.formData[key] != this.oldImage[key]) {
        await this.axios.post(process.env.VUE_APP_FUNCTIONS_BACKEND + 'imageDelete', {
          key: key,
          docId: this.id,
          image: this.oldImage[key],
        })
      }
    },
    async setStoragePath(image, key) {
      const downloadUrl = await this.getStoragePath(image)
      if (downloadUrl) {
        this.viewImage[key] = downloadUrl
      }
      this.oldImage[key] = image
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
