import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import './index.css'
import { auth } from './firebaseInit.js'

let app

// ログイン中、認証ステータスに変更がない限りはページをリロードしても該当のページが表示されるようにする
auth.onAuthStateChanged(() => {
  if (!app) {
    app = createApp(App)
      .use(router)
      .use(VueAxios, axios)
      .mount('#app')
  }
})
