<template>
  <div v-show="!sendComplete" class="overflow-hidden bg-white shadow sm:rounded-lg">
    <div class="px-4 py-5 sm:px-6">
      <h3 class="text-lg font-medium leading-6 text-gray-900">プロフィール</h3>
      <p class="mt-4 max-w-2xl text-lg text-gray-500 lg:mx-auto">
        ご希望の<router-link to="/template" class="underline">デザインテンプレート</router-link>を選択して「申請」ボタンを押してください。<br>
        デザインテンプレートの反映後、プロフィールページがご利用可能になります。<br>
        (テンプレートの反映は数日かかる場合がございます。)
      </p>
    </div>
    <div class="border-t border-gray-200">
      <dl>
        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">名前</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{ profile.name }}</dd>
        </div>
        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">アイコン画像</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            <img v-if="profile.viewIcon" :src="profile.viewIcon" class="w-1/2 mx-auto">
          </dd>
        </div>
        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">一言</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{ profile.comment }}</dd>
        </div>
        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">実績</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{ profile.works }}</dd>
        </div>
        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">あなたを表すハッシュタグ</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{ profile.tags }}</dd>
        </div>
        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">ウェブサイト</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            <a :href="profile.website" target="_blank">{{ profile.website }}</a>
          </dd>
        </div>
        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">お貢ぎの有無</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{ profile.ispay }}</dd>
        </div>
        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">お貢ぎのタイミング</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{ profile.timing }}</dd>
        </div>
        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">対応決済</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{ profile.payment }}</dd>
        </div>
        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">お貢ぎ先 (QR画像)</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            <img v-if="profile.viewQr" :src="profile.viewQr" class="w-1/2 mx-auto">
          </dd>
        </div>
        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">M男くんに求める条件</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{ profile.condition }}</dd>
        </div>
        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">私の調教はここが違うよ！</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{ profile.feature }}</dd>
        </div>
        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">調教方法(DM)</dt>
          <dd v-show="profile.dm" class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">○</dd>
        </div>
        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">調教方法(通話)</dt>
          <dd v-show="profile.tel" class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">○</dd>
        </div>
        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">調教方法(ボイメ)</dt>
          <dd v-show="profile.voice" class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">○</dd>
        </div>
        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">調教方法(カカオ)</dt>
          <dd v-show="profile.kakao" class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">○</dd>
        </div>
        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">調教メニュー</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{ profile.menu }}</dd>
        </div>
        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 text-center">
          <dt class="text-sm font-medium text-gray-500">調教イメージ (DMスクショなど)</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            <img v-if="profile.viewScreenshot" :src="profile.viewScreenshot" class="w-1/2 mx-auto">
          </dd>
        </div>
      </dl>
    </div>
  </div>

  <div class="hidden sm:block" aria-hidden="true">
    <div class="py-5" />
  </div>

  <div v-if="sendTemplate" class="container mx-auto sticky bottom-0">
    <div class="bg-gray-100 px-4 py-3 text-right sm:px-6">
      <select v-model.number="formData.selectedTemplate" id="selectedTemplate" name="selectedTemplate" class="mt-1 mr-4 inline-block w-4/6 sm:w-1/4 rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm">
        <option :value="0">テンプレートを選択して下さい</option>
        <option :value="1">プレーン</option>
        <option :value="2">フルーツ</option>
        <option :value="3">パープル</option>
        <option :value="4">Unbrella</option>
        <option :value="5">Mountain</option>
        <option :value="6">ブルー</option>
        <option :value="7">フェザー</option>
      </select>
      <button @click="send" type="submit" class="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">申請</button>
      <p v-if="v$.formData.selectedTemplate.$error" class="mt-2 text-sm text-red-500 text-right">{{ v$.formData.selectedTemplate.$errors[0].$message }}</p>
    </div>
  </div>

  <div v-show="sendComplete" class="bg-white py-12">
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="lg:text-center">
        <p class="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">テンプレートを申し込みました</p>
        <p class="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">テンプレートの反映は数日かかる場合がございます。反映までもうしばらくお待ちください。</p>
      </div>
    </div>
  </div>

</template>

<script>
import { auth } from '@/firebaseInit.js'
import { useVuelidate } from '@vuelidate/core'
import { helpers, required, integer, between } from '@vuelidate/validators'

// see: https://vuelidate-next.netlify.app/
export default {
  name: 'DefaultProfile',
  props: {
    profile: Object
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      formData: {
        uid: '',
        selectedTemplate: 0
      },
      sendTemplate: false,
      sendComplete: false,
    }
  },
  async created() {
    if(this.profile.template === 0) {
      this.sendTemplate = true
    }
  },
  validations () {
    return {
      formData: {
        uid: {
          required,
        },
        selectedTemplate: {
          required: helpers.withMessage('ご希望のテンプレートを選んでください', required),
          integer,
          betweenValue: helpers.withMessage('テンプレートを選んでください', between(1, 7)),
        },
      }
    }
  },
  methods: {
    async send () {
      this.formData.uid = auth.currentUser.uid

      const result = await this.v$.$validate()
      if (!result) {
        return
      }

      this.axios.post(process.env.VUE_APP_FUNCTIONS_BACKEND + 'templateMail', {
        uid: this.formData.uid,
        selectedTemplate: this.formData.selectedTemplate
      })
      .then(() => {
        this.sendTemplate = false
        this.sendComplete = true
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
