<template>
  <Popover class="relative bg-white">
    <div class="mx-auto max-w-7xl px-4 sm:px-6">
      <div class="flex items-center justify-between border-b-2 border-gray-100 py-3 sm:py-0 md:justify-start md:space-x-10">
        <div class="flex justify-start lg:w-0 lg:flex-1">
          <router-link to="/">
            <span class="sr-only">S-Profile</span>
            <img class="h-8 w-auto sm:h-10" alt="logo" src="../assets/logo.png">
          </router-link>
        </div>
        <div class="-my-2 -mr-2 md:hidden">
          <PopoverButton class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
            <span class="sr-only">Open menu</span>
            <Bars3Icon class="h-6 w-6" aria-hidden="true" />
          </PopoverButton>
        </div>
        <PopoverGroup as="nav" class="hidden space-x-10 md:flex">
          <router-link to="/" class="text-base font-medium text-gray-500 hover:text-gray-900">トップページ</router-link>
          <router-link to="/template" class="text-base font-medium text-gray-500 hover:text-gray-900">デザインテンプレート</router-link>
          <router-link v-if="login" :to="{ name: 'profile', params: { id: id }}" class="text-base font-medium text-gray-500 hover:text-gray-900">プロフィール</router-link>
          <router-link v-if="login" to="/profile_edit" class="text-base font-medium text-gray-500 hover:text-gray-900">プロフィール登録</router-link>
          <router-link to="/premium" class="text-base font-medium text-gray-500 hover:text-gray-900">プレミアム会員</router-link>
        </PopoverGroup>
        <div class="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
          <a v-if="login" href="#" @click.stop.prevent="signout" class="ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700">ログアウト</a>
          <router-link v-else to="/login" class="ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700">ログイン</router-link>
        </div>
      </div>
    </div>

    <transition enter-active-class="duration-200 ease-out" enter-from-class="opacity-0 scale-95" enter-to-class="opacity-100 scale-100" leave-active-class="duration-100 ease-in" leave-from-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-95">
      <PopoverPanel focus class="absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden z-10">
        <div class="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div class="px-5 pt-5 pb-6">
            <div class="flex items-center justify-between">
              <div>
              <img class="h-8 w-auto" alt="logo" src="../assets/logo.png">
              </div>
              <div class="-mr-2">
                <PopoverButton class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span class="sr-only">Close menu</span>
                  <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </PopoverButton>
              </div>
            </div>
          </div>
          <div class="space-y-6 py-6 px-5">
            <div class="grid grid-cols-2 gap-y-4 gap-x-8">
              <router-link to="/" class="text-base font-medium text-gray-900 hover:text-gray-700">トップページ</router-link>
              <router-link to="/template" class="text-base font-medium text-gray-900 hover:text-gray-700">デザインテンプレート</router-link>
              <router-link v-if="login" :to="{ name: 'profile', params: { id: id }}" class="text-base font-medium text-gray-900 hover:text-gray-700">プロフィール</router-link>
              <router-link v-if="login" to="/profile_edit" class="text-base font-medium text-gray-900 hover:text-gray-700">プロフィール登録</router-link>
              <router-link to="/premium" class="text-base font-medium text-gray-900 hover:text-gray-700">プレミアム会員</router-link>
            </div>
            <div>
              <a v-if="login" href="#" @click.stop.prevent="signout" class="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700">ログアウト</a>
              <router-link v-else to="/login" class="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700">ログイン</router-link>
            </div>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script>
import { auth, db, query, collection, where, getDocs } from '@/firebaseInit.js'
import { Popover, PopoverButton, PopoverGroup, PopoverPanel } from '@headlessui/vue'
import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/vue/24/outline'

export default {
  name: 'HeaderComponent',
  components: {
    Popover, 
    PopoverButton, 
    PopoverGroup, 
    PopoverPanel,
    Bars3Icon,
    XMarkIcon
  },
  data() {
    return {
      login: false,
      id: 'notfound',
    }
  },
  async created() {
    let user = auth.currentUser
    if (user) {
      this.login = true
      this.getProfiles(user.uid)
    }
  },
  methods: {
    signout () {
      auth.signOut().then(() => {
        this.$router.push('/')

      }).catch((error) => {
        console.log(error)
      })
    },
    async getProfiles (uid) {
      const q = query(collection(db, "profiles"), where("uid", "==", uid))

      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        this.id = doc.id
      })
    }    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
