<template>
  <div class="bg-white py-12">
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="lg:text-center">
        <h2 class="text-lg font-semibold text-indigo-600">S-Profile</h2>
        <p class="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">S女様向けプロフィールサービス</p>
        <p class="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">S-Profileは、M男さんを調教している方へのプロフィールサービスです。M男さんに知っておいて欲しいあなたの情報を予め登録しておくことができます。</p>
      </div>
      <div class="mx-auto mt-10 px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-16">
        <div class="bg-gray-100 p-4 sm:p-8">
          <div class="text-left">
            <p class="mt-3 text-base text-gray-500 sm:mx-auto sm:mt-5 sm:text-lg md:mt-5 md:text-xl lg:mx-0 mb-5">
              M男さんと知り合う度に、調教の条件等について何度も同じことを伝えていませんか？<br>
              伝えることが多くtwitterの中では上手く伝えられない、また、M男さんがちゃんと確認してくれない。<br>
              そのような経験をしたことはありませんか？<br>
              <br>
              S-Profileを使うと、自身のことや調教の条件についてのプロフィールページを作成し、1ページにまとめておくことができます。<br>
              プロフィールを作成後は、そのプロフィールページへのリンクをM男さんに知らせるだけです<br>
              一つずつ調教の条件を確認したりするよりも大きく手間は省くことができます。<br>
              <br>
              また、以下はプレミアム会員向けの機能になりますが、<br>
              twitterで活動していると、アカウントが凍結されることがあり、<br>
              アカウントを作り直した時に、また同じプロフィールを登録しなければなりません。<br>
              このサービスにプロフィールを登録しておくと、<br>
              凍結されたアカウントで使っていた情報を新しいアカウントに簡単に引き継ぐことができます。<br>
              他人に見せたくないプロフィール情報をPCやスマホに保存しておく必要もありません。<br>
              <br>
              そして、フォロワーの管理もできます。フォロワーの情報を保存しておくことで、<br>
              新しいアカウントに引き継いだ際に旧アカウントのフォロワーを把握することができます。<br>
              大切だったフォロワーに新しいアカウントを作ったことを知らせることもできるでしょう。
            </p>
          </div>
        </div>
      </div>

      <div class="hidden sm:block" aria-hidden="true">
        <div class="py-5" />
      </div>

      <div class="lg:text-center">
        <p class="mt-4 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">ベータ版</p>
      </div>
      <div class="mx-auto mt-10 px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-16">
        <div class="bg-gray-100 p-4 sm:p-8">
          <div class="text-left">
            <p class="mt-3 text-base text-gray-500 sm:mx-auto sm:mt-5 sm:text-lg md:mt-5 md:text-xl lg:mx-0 mb-5">
              現在、このサービスはベータ版です。<br>
              <br>
              機能に重大な問題があったり、利用者数が少ない場合に早期にサービスを終了する場合がございます。<br>
              しかし、多くの方にご利用いただくために、新機能の追加やできる限りの問題の解消を行っていく予定です。<br>
              利用できる機能が増えるプレミアム会員も、期間限定の特別価格で提供させて頂いております。<br>
              <br>
              最新情報は、<a href="https://twitter.com/S_Profile_" target="_blank">@S_Profile_</a> にて随時お知らせしてまいります。<br>
              ぜひアカウントのフォローをお待ちしております。<br>
            </p>
          </div>
        </div>
      </div>

      <div class="hidden sm:block" aria-hidden="true">
        <div class="py-5" />
      </div>

      <div class="lg:text-center">
        <p class="mt-4 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">ご利用方法</p>
      </div>
      <div class="mx-auto mt-10 px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-16">
        <div class="bg-gray-100 p-4 sm:p-8">
          <div class="text-left">
            <p class="mt-3 text-base text-gray-500 sm:mx-auto sm:mt-5 sm:text-lg md:mt-5 md:text-xl lg:mx-0 mb-5">
              利用をご希望の方はログインページより利用者登録を行ってください。<br>
              その後、プロフィール登録ページよりプロフィールに表示したい項目をご入力ください。<br>
              <br>
              登録できましたら、プロフィールページより使いたいテンプレートをデザインテンプレート一覧から選んで申請してください。<br>
              申請受付後、テンプレートの反映手続きを行わせていただきます。<br>
              (現在、反映手続きはスタッフが手動で行っているため、反映は数日かかる場合がございます)<br>
              <br>
              テンプレート反映後、あなた以外の人からもプロフィールを閲覧することができるようになります。<br>
              Twitterなどでページを拡散してM男さんに見て頂きましょう。
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from '@/firebaseInit.js'
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  methods: {
    signout () {
      auth.signOut().then(() => {
        console.log("user logout.")

      }).catch((error) => {
        console.log(error)
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
