import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import TemplateView from '../views/TemplateView.vue'
import ProfileView from '../views/ProfileView.vue'
import ProfileEditView from '../views/ProfileEditView.vue'
import PremiumView from '../views/PremiumView.vue'
import { auth, analytics, logEvent } from '../firebaseInit.js'

// see: https://devsakaso.com/firebase-vue-js-create-login-form/

// auth guard 
const requireAuth = (to, from, next) => {
  let user = auth.currentUser
  if (!user) {
    // not authoricated
    next({ name: 'about' })
    // これだけだとリロードするたびに認証がとれなくなるのでmain.jsを修正する
  } else {
    next()
  }
}

// no auth guard
/*
const requireNoAuth = (to, from, next) => {
  let user = auth.currentUser
  if (user) {
    // authoricated
    next({ name: 'home' })
  } else {
    next()
  }
}
*/

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  },
  {
    path: '/template',
    name: 'template',
    component: TemplateView
  },
  {
    path: '/profile/:id',
    name: 'profile',
    component: ProfileView
  },
  {
    // See: https://qiita.com/yuneco/items/5e526464939082862f5d
    path: '/_profile/:id',
    redirect: to => {
      return { path: `/profile/${to.params.id}` }
    }
  },
  {
    path: '/profile_edit',
    name: 'profile_edit',
    component: ProfileEditView,
    beforeEnter: requireAuth
  },
  {
    path: '/premium',
    name: 'premium',
    component: PremiumView
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.afterEach((to) => {
  logEvent(analytics, 'page_view', {
    page_location: to.path
  })
})

export default router
