<template>
  <div class="bg-white">
    <div class="mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
      <h2 class="text-2xl font-bold tracking-tight text-gray-900">デザインテンプレート一覧</h2>

      <div class="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
        <div v-for="product in products" :key="product.id" class="group relative">
          <div class="min-h-80 aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 lg:aspect-none lg:h-80">
            <img :src="product.imageSrc" :alt="product.imageAlt" class="h-full w-full object-cover object-top lg:h-full lg:w-full" />
          </div>
          <div class="mt-4 flex justify-between">
            <div>
              <h3 class="text-sm text-gray-700">
                <a :href="product.href" target="_blank" class="underline">
                  <span aria-hidden="true" class="absolute inset-0" />
                  {{ product.name }}
                </a>
              </h3>
              <p class="mt-1 text-sm text-gray-500">{{ product.color }}</p>
            </div>
            <p class="text-sm font-medium text-gray-900">{{ product.price }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TemplateList',
  data() {
    return {
      products: [
        {
          id: 1,
          name: 'プレーン',
          href: require('@/assets/plane.png'),
          imageSrc: require('@/assets/plane.png'),
          imageAlt: "プレーンテンプレート",
          price: '',
          color: '',
        },
        {
          id: 2,
          name: 'フルーツ',
          href: require('@/assets/fruit.png'),
          imageSrc: require('@/assets/fruit.png'),
          imageAlt: "フルーツテンプレート",
          price: '',
          color: '',
        },
        {
          id: 3,
          name: 'パープル',
          href: require('@/assets/purple.png'),
          imageSrc: require('@/assets/purple.png'),
          imageAlt: "パープルテンプレート",
          price: '',
          color: '',
        },
        {
          id: 4,
          name: 'Unbrella',
          href: require('@/assets/unbrella.png'),
          imageSrc: require('@/assets/unbrella.png'),
          imageAlt: "Unbrellaテンプレート",
          price: '',
          color: '',
        },
        {
          id: 5,
          name: 'Mountain',
          href: require('@/assets/mountain.png'),
          imageSrc: require('@/assets/mountain.png'),
          imageAlt: "Mountainテンプレート",
          price: '',
          color: '',
        },
        {
          id: 6,
          name: 'ブルー',
          href: require('@/assets/blue.png'),
          imageSrc: require('@/assets/blue.png'),
          imageAlt: "ブルーテンプレート",
          price: '',
          color: '',
        },
        {
          id: 7,
          name: 'フェザー',
          href: require('@/assets/fether.png'),
          imageSrc: require('@/assets/fether.png'),
          imageAlt: "フェザーテンプレート",
          price: '',
          color: '',
        },
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
